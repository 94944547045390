const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const OKTA_ORG_DOMAIN = process.env.REACT_APP_OKTA_ORG_DOMAIN;
const OKTA_AUTH_SERVER = process.env.REACT_APP_OKTA_AUTH_SERVER || "default";
const API_URL = process.env.REACT_APP_API_URL || "";

const SLACK_API_KEY = process.env.REACT_APP_SLACK_API_KEY || "";
const SLACK_CHANNEL_ID = process.env.REACT_APP_SLACK_CHANNEL_ID || "";

const BUCKET_OBJECT_URL = process.env.REACT_APP_BUCKET_OBJECT_URL || "";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "";

export interface Config {
  oidc: {
    clientId: string;
    issuer: string;
    redirectUri: string;
  };
  api: {
    url: string;
  };
  slack: {
    token: string;
    channel: string;
  };
  forecast: {
    weeksInPast: number;
    weeksInFuture: number;
  };
  bucket: {
    bucket_object_url: string;
  };
  woosmap: {
    apiKey: string;
  };
  environment: string;
}

const config: Config = {
  oidc: {
    clientId: OKTA_CLIENT_ID,
    issuer: `https://${OKTA_ORG_DOMAIN}/oauth2/${OKTA_AUTH_SERVER}`,
    redirectUri: `${window.location.origin}/implicit/callback`,
  },
  api: {
    url: API_URL,
  },
  slack: {
    token: SLACK_API_KEY,
    channel: SLACK_CHANNEL_ID,
  },
  forecast: {
    weeksInPast: 53,
    weeksInFuture: 6,
  },
  bucket: {
    bucket_object_url: BUCKET_OBJECT_URL,
  },
  woosmap: {
    apiKey: process.env.REACT_APP_WOOSMAP_API_KEY!,
  },
  environment: ENVIRONMENT,
};

export default config;
