import React from "react";
import { useQuery } from "@apollo/client";
import { GET_OPENING_HOURS } from "../../../gql";
import { format } from "date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import { ICombinedRecord } from "../../../types";

interface props {
  row: ICombinedRecord;
}
export const OpeningHoursCell = ({ row }: props) => {
  const { businessDate, restaurantId } = row;

  const { data, loading, error } = useQuery(GET_OPENING_HOURS, {
    variables: {
      date: businessDate,
      cost_centre: restaurantId,
    },
    fetchPolicy: "no-cache",
  });

  if (loading) return <CircularProgress size={15} />;
  if (error) return <div>-</div>;

  const { open_time, close_time } = data.opening_hours;

  if (!open_time || !close_time) return <div>-</div>;

  const open = format(new Date(open_time), "HH:mm");

  const close = format(new Date(close_time), "HH:mm");

  return (
    <div style={{ textAlign: "right" }}>
      {open} - {close}
    </div>
  );
};
